<template>
  <div class="login">
    <!-- login page using boostrap-vue in japanese -->
    <b-container>
      <b-row>
        <!-- Boostrap vue alert error for message -->
        <b-col md="12" v-if="message">
          <b-alert variant="danger" show>{{ message }}</b-alert>
        </b-col>
        <b-form @submit="login">
          <b-col md="6">
            <b-form-group label="Email" label-for="username">
              <b-input id="username" v-model="username" type="text" placeholder="Email" autocomplete="off" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Password" label-for="password">
              <b-input id="password" v-model="password" type="password" placeholder="password" autocomplete="off" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-button type="submit" class="primary">Login</b-button>
            <b-button type="reset" style="margin-left:2px" class="secondary">Reset</b-button>
          </b-col>
        </b-form>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    data () {
        return {
            username: '',
            password: '',
            message: ''
        }
    },
    methods: {
        login (e) {
            e.preventDefault()
            // axios post request for login form to localhost:3000/auth/login
            axios.post(`${process.env.VUE_APP_BACK_URL}/auth/login`, {
                email: this.username,
                password: this.password
            }, { withCredentials: true }).then(response => {
                console.log(response)
                if (response.status === 200) {
                    // redirect to /
                    console.log('Ok')
                    this.message = response.data.message
                    window.location.href = '/'
                } else {
                    // show error message
                    this.error = response.data.message
                }
            })
                .catch(error => {
                // show error message
                    this.message = error.response.data.message
                })
        },
        reset (e) {
            e.preventDefault()
            this.username = ''
            this.password = ''
        }
    }
}
</script>
<style>
    .login-title {
        background: #fafafa;
        border-bottom: 1px solid #e9e9e9;
        height: 50px;
        line-height: 50px;
        padding: 15px 0;
    }

    .login-title img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .login-title h3 {
        color: #333;
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    .login-content {
        background: #fff;
        border-bottom: 1px solid #e9e9e9;
        height: auto;
        padding: 15px 0;
    }

    .login-form {
        background: #fff;
        border-bottom: 1px solid #e9e9e9;
        box-sizing: border-box;
        height: auto;
        margin-bottom: 15px;
        padding: 15px 0;
    }

    .login-input {
        display: block;
        margin-bottom: 10px;
    }

    .login-input-text {
        height: 30px;
        line-height: 30px;
        padding: 0;
    }

    .login-btn {
        background: #fff;
        border: 0;
        border-radius: 4px;
        color: #333;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        line-height: 40px;
        margin: 0;
        padding: 0 25px;
        text-align: center;
    }

    .login-footer {
        background: #fff;
        border-top: 1px solid #e9e9e9;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .login-footer a {
        color: #333;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
    }

    .login-footer a:hover {
        text-decoration: underline;
    }
</style>
