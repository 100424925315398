<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="error" v-if="error">
            <div class="alert alert-warning" role="alert">
              {{ error }} - {{ message }}
            </div>
          </div>
          <b-form @submit="submitFile" @reset="onReset" class="mt-4">
            <b-form-file
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept="image/jpeg, image/jpg, image/png"
              type="file"
              required
            ></b-form-file>
            <b-button type="submit" variant="outline-warning">Submit</b-button>
          </b-form>
          <p>
            Your image ID is: <b>{{ img }}</b> <br />
            img URL:
            <a
              :href="link"
              target="_blank"
              rel="noopener noreferrer"
            >{{ link }}</a>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            file1: '',
            error: '',
            message: '',
            img: '',
            link: ''
        }
    },
    methods: {
        submitFile (event) {
            event.preventDefault()
            const formData = new FormData()
            formData.append('file', this.file1)
            axios
                .post(
                    `${process.env.VUE_APP_BACK_URL}/api/upload`,
                    formData,
                    { withCredentials: true },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(res => {
                    const data = res.data
                    console.log(data)
                    this.img = data.img
                    this.link = data.link
                    this.file1 = ''
                })
                .catch(error => {
                    this.error = error.code || 'Yikes! it went wrong :c'
                    this.message = error.message
                })
        },
        onReset (event) {
            event.preventDefault()
        }
    }
}
</script>
