<template>
  <div class="AuthPage">
    <p><span class="link" @click="comp = 'login'">Login</span> | <span class="link" @click="comp = 'register'">Register</span></p>
    <div class="loginComp" v-if="comp === 'login'">
      <login />
    </div>
    <div class="registerComp" v-if="comp == 'register'">
      <Register />
    </div>
  </div>
</template>

<script>
import login from '../../components/AuthComp/login.vue'
import Register from '../../components/AuthComp/register.vue'
export default {
    name: 'AuthPage',
    components: {
        login,
        Register
    },
    data () {
        return {
            comp: 'login'
        }
    }
}
</script>

<style>
.AuthPage {
    padding: 10px;
    text-align: center;
}
.link {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}
</style>
