<template>
  <div class="userProfile">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h1>Welcome to your profile!</h1>
          <p>
            Nothing much at the moment, but this will be updated
            later!
          </p>
        </b-col>
        <b-col cols="6">
          <ul>
            <li>Username: {{ username }}</li>
            <li>user ID: {{ userId }}</li>
            <li>E-mail: {{ email }}</li>
          </ul>
        </b-col>
        <b-col cols="6">
          <p>
            <a href="#">Change Password</a> <i> Soon! </i> <br>
            <a href="#">Change Email</a> <i> Soon! </i>
          </p>
        </b-col>
        <a href="/logout"><b-button>Logout</b-button></a>
        <b-col cols="12">
          <p>NOTICE: This website is still in alpha. When it moves to Beta, the database will be wiped, and all images deleted. Take this in concideration before deleting anything
            from your hard drives or use this serive to host image for production. <br>
            This website may be taken down at anytime without futher notice for Maintenance, upgrades or any other reason. <br>
            If any part of this service dont work as inrtended please check our <a href="https://Asthriona.statuspage.io">Status Page</a> for more information.
            if you have any questions or suggestions, please contact us at <a href="mailto:Contact@asthriona.com">Contact@Asthriona.com</a>.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <h1>your images:</h1>
        <b-col cols="3" v-for="img in imgs" :key="img">
          <router-link :to="`/i/${img.img}`"><img :src="`https://cdn.nishikino.xyz/profectFucker/${img.fileName}`" width="100%"></router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            username: '',
            userId: '',
            email: '',
            imgs: []
        }
    },
    beforeMount () {
        axios
            .get(`${process.env.VUE_APP_BACK_URL}/user`, { withCredentials: true })
            .then(response => {
                this.username = response.data.username
                this.userId = response.data.userId
                this.email = response.data.email
            })
    },
    mounted () {
        axios.get(`${process.env.VUE_APP_BACK_URL}/user/images`, { withCredentials: true })
            .then(response => {
                this.imgs = response.data
            })
    }
}
</script>
