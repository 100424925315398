<template>
  <div class="img">
    <hr />
    <b-container>
      <b-row>
        <div v-if="error">
          <h1>ERROR: {{ error }}</h1>
          <h3>{{ message }}</h3>
        </div>
        <div v-else>
          <div class="text-center">
            <h3>Image ID: {{ imgId }}</h3>
          </div>
          <b-img class="img" :src="imgLink" fluid :alt="imgId"></b-img>
        </div>
        <a :href="imgLink" class="float-left">Full size image</a>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'imgView',
    data () {
        return {
            imgId: '',
            imgLink: '',
            error: '',
            message: ''
        }
    },
    beforeMount () {
        axios
            .get(`${process.env.VUE_APP_BACK_URL}/i/` + this.$route.params.slug)
            .then(res => {
                console.log(res.data)
                if (res.data.error) {
                    this.error = res.data.error
                    return (this.message = res.data.message)
                }
                this.imgId = res.data.imgId
                this.imgLink = res.data.imgLink
            })
    }
}
</script>

<style scoped>
.img {
  max-height: 720px;
  text-align: center;
}
a{
    color: #d86100;
}
</style>
