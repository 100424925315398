<template>
  <div id="app">
    <div class="alert" v-if="alert_active == 'true'">
      <div class="alertBack">
        <router-link style="color:white" to="/info"><h3><b-icon icon="wrench"></b-icon> {{alert_message}}</h3></router-link>
      </div>
    </div>
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/profile">Profile</router-link>
    </div>
    <router-view />
    <br>
    <div id="Maki"></div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            alert_active: '',
            alert_message: ''
        }
    },
    beforeMount () {
        axios.get(`${process.env.VUE_APP_BACK_URL}/api/alert`)
            .then(res => {
                const data = res.data
                if (data.active === 'false') {
                    this.alert_active = 'false'
                }
                this.alert_active = 'true'
                this.alert_message = data.message
            })
    }
    // mounted () {
    //     const Maki = document.createElement('script')
    //     Maki.setAttribute('src', 'https://cdn.nishikino.xyz/network/nishikinoNetworks.js')
    //     document.head.appendChild(Maki)
    // }
}
</script>

<style>
  html, body {
  color: #cecece;
  background-color: #212226;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #cecece;
  background-color: #212226;
}

#nav {
  text-align: center;
  padding: 30px;
}
a{
    color: #d86100;
}
#nav a {
  font-weight: bold;
  color: #eeeeee;
}

#nav a.router-link-exact-active {
  color: #d86100;
}
.alertBack {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: red;
}
</style>
