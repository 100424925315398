<template>
  <div class="register">
    <b-container>
      <b-row>
        <b-col cols="12" v-if="message">
          <div class="text-center">
            <h1 style="color:red">{{ message }}</h1>
          </div>
        </b-col>
        <b-form @submit="Register">
          <b-form-group label="Username" required>
            <b-input
              v-model="form.username"
              type="text"
              placeholder="Username"
            />
          </b-form-group>

          <b-form-group label="email">
            <b-input
              v-model="form.email"
              type="email"
              placeholder="Email"
            />
          </b-form-group>

          <b-form-group label="Password" required>
            <b-input
              v-model="form.password"
              type="password"
              placeholder="Password"
            />
          </b-form-group>
          <button type="submit" class="btn btn-primary">
            Register
          </button>
        </b-form>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            message: '',
            form: [{ username: '', email: '', password: '' }]
        }
    },
    methods: {
        Register (event) {
            event.preventDefault()
            axios
                .post(
                    `${process.env.VUE_APP_BACK_URL}/auth/register`,
                    {
                        username: this.form.username,
                        email: this.form.email,
                        password: this.form.password
                    },
                    { withCredentials: true }
                )
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    this.message = err.response.data.message
                })
        }
    }
}
</script>
