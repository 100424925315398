<template>
  <div class="home">
    <img alt="imgUp Logo" src="https://cdn.nishikino.xyz/profectFucker/Static/front/ninjacat.png">
    <HelloWorld msg="Asthriona - Image Uploader" />
    <h1>Hello {{ username || "Anonyme user" }}!</h1>
    <div class="NotLoggedin" v-if="!userId">
      <p>Please login to continue</p>
      <router-link to="/login" class="btn btn-primary">Login</router-link>
    </div>
    <div class="uploader" v-else>
      <FileUpload />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import FileUpload from '@/components/FileUploader.vue'
import axios from 'axios'
export default {
    name: 'Home',
    data () {
        return {
            username: '',
            userId: '',
            isAdmin: '',
            isVerified: ''
        }
    },
    components: {
        HelloWorld,
        FileUpload
    },
    beforeMount () {
        axios.get(`${process.env.VUE_APP_BACK_URL}/user`, { withCredentials: true })
            .then(response => {
                this.username = response.data.username
                this.isAdmin = response.data.isAdmin
                this.isVerified = response.data.isVerified
                this.userId = response.data.userId
            }).catch(error => {
                console.log(error)
            })
    }
}
</script>

<style scoped>
.home {
  text-align: center;
}
</style>
