<template>
  <div></div>
</template>

<script>
export default {
    mounted () {
        window.location.href = `${process.env.VUE_APP_BACK_URL}/auth/logout`
    }
}
</script>
